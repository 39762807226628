<template>
  <div class="mx-auto">
    <form @submit.prevent="handleSubmit" class="mx-auto bg-white container p-3">
      <h3 class="">ICD10 Data Maintenance</h3>
      <FileInput
        ref="fileInput"
        accept="txt"
        label="Addenda File"
        @fileInsert="handleFileSelection"
        multiple="false"
        required
      />
      <div>
        <i class="text-danger">Please, make sure to upload the ICD 10 <b>order</b> addenda file.</i>
      </div>
      <DatePicker label="Effective On" v-model="effectiveOn" required :validator="$v.effectiveOn" />
      <div class="d-flex mt-2">
        <button :disabled="isLoading" class="ml-auto btn btn-primary">Upload</button>
      </div>
    </form>
  </div>
</template>

<script>
import FileInput from "@/components/FileInput.vue";
import MaintenanceService from "@/services/maintenance.js";
import { format } from "date-fns";
import { minValue, required } from "vuelidate/lib/validators";
import DatePicker from "@/components/common/DatePicker.vue";

export default {
  name: "ICD-Codes",
  metaInfo: {
    title: "ICD Master File",
    titleTemplate: "IntelliPath - %s"
  },
  components: {
    FileInput,
    DatePicker
  },
  data() {
    return {
      isLoading: false,
      file: "HELLO WORLD",
      effectiveOn: new Date()
    };
  },
  validations: {
    effectiveOn: {
      noPastDates: minValue(
        (function () {
          let v = new Date();
          v.setHours(-1);
          return v;
        })()
      )
    },
    file: {
      required
    }
  },
  mounted() {
    this.resetData();
  },
  methods: {
    handleFileSelection(e) {
      this.file = e[0];
    },
    handleSubmit() {
      const formData = new FormData();
      formData.append("EffectiveOn", format(this.effectiveOn, "MM/dd/yyyy"));
      formData.append(this.file.name, this.file);
      this.isLoading = true;
      MaintenanceService.uploadAddendaFile(formData)
        .then(res => {
          const { added, deleted, revised, totalFailed } = res;
          alert(
            `Succes!\nAdded: ${added}\nDeleted: ${deleted}\nRevised: ${revised}\nTotal Failed: ${totalFailed}`
          );
          this.isLoading = false;
          return this.resetData();
        })
        .catch(() => {
          window.notify("Error, please check your input and try again.", "warning");
          this.isLoading = false;
        });
    },
    resetData() {
      const fileInput = this.$refs.fileInput;
      if (fileInput) {
        fileInput?.clear();
      }
      this.file = null;
      this.effectiveOn = new Date();
    }
  }
};
</script>

<style lang="scss" scoped></style>
