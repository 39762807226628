<template>
  <div class="form-group">
    <label :for="id"
      ><b>{{ label }}</b></label
    >
    <input
      type="file"
      ref="input"
      :id="id"
      v-bind="$attrs"
      class="form-control-file"
      :accept="accept"
      @change="updateValue($event)"
    />
  </div>
</template>

<script>
export default {
  name: "FileInput",
  props: {
    label: {
      type: String
    },
    id: {
      type: String
    },
    accept: {
      type: String,
      default() {
        return ".zip,.pdf";
      }
    }
  },
  methods: {
    updateValue(e) {
      this.$emit("fileInsert", e.target.files);
    },
    clear() {
      this.$refs.input.value = "";
    }
  }
};
</script>

<style scoped lang="scss"></style>
